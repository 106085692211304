import { getToApi } from "./requestManager";
import { PostAPIWrapper } from "../features/postIdea/postsSlice";
import { UrlUtils } from "../features/utils/urlUtils";
import { HolidayLayoutPreviewAPI } from "./business.services";

export const holidayLayoutServices = {
  getHolidayLayoutData,
  getHolidayLayouts,
};

const HOLIDAY_LAYOUTS_BASE_PATH = "holiday_layouts";
const HOLIDAY_LAYOUT_DATA_PATH = "holiday_layout_data";
const HOLIDAY_LAYOUTS_LIST_ENDPOINT = "list"

export interface HolidayLayoutDataAPI
{
  slug: string;
  post_json: PostAPIWrapper;
  image_url: string;
  format_description: string;
}

async function getHolidayLayoutData( slug: string ): Promise<HolidayLayoutDataAPI>
{
  const endPoint = buildMemberUrl( slug, HOLIDAY_LAYOUT_DATA_PATH );

  return getToApi<HolidayLayoutDataAPI>( endPoint ).then( ( data ) =>
  {
    return data;
  } );
}

export interface HolidayLayoutsListAPI
{
  layouts: HolidayLayoutPreviewAPI[],
}

async function getHolidayLayouts( postIdeaId: string )
{
  try
  {
    const endpointUrl = new UrlUtils().buildUrl( HOLIDAY_LAYOUTS_BASE_PATH, [HOLIDAY_LAYOUTS_LIST_ENDPOINT] );
    const queryParams = {
      post_idea_id: postIdeaId,
    }
    return await getToApi<HolidayLayoutsListAPI>( endpointUrl, queryParams );
  }
  catch (error)
  {
    return Promise.reject( "Could not fetch holiday layouts" );
  }
}

function buildMemberUrl( slug, path )
{
  return new UrlUtils().buildUrl( HOLIDAY_LAYOUTS_BASE_PATH, [slug, path] )
}
