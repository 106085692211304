import React from 'react';
import ReactDOM from 'react-dom/client';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './index.scss';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { persistor, store } from './app/store';
import { Provider } from 'react-redux';
import { PersistGate } from "redux-persist/integration/react";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import * as Sentry from "@sentry/react";
import { ENABLE_ERROR_REPORTING, ENABLE_USAGE_TRACKING, ENV_CONFIG } from "./constants";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./features/error/errorPage";
import { ROUTES } from "./features/constants";
import { UserSettings } from "./features/userSettings/userSettings";
import { NavigateWithParams } from "./features/NavigateWithParams";
import { AssistantChat } from "./features/assistantChat/assistantChat";
import { PlanTab } from "./features/planner/planTab";
import { SignInLandingPage } from "./features/registration/signInLandingPage";
import { Branding } from "./features/brandStyle/branding";
import { PrivateRoute } from "./features/routing/privateRoute";
import { MediaLibraryTab } from "./features/mediaLibrary/mediaLibraryTab";
import { StyleQuiz } from "./features/styleQuiz/styleQuiz";
import { gtagWrapper } from "./helpers/gtagWrapper";
import { SocialNetworkAccountsSection } from "./features/userSettings/SocialNetworkAccountsSection";

// Augment the palette to include custom colors
declare module '@mui/material/styles'
{
  interface Palette
  {
    positive: Palette['primary'];
    negative: Palette['primary'];
    lightPurple: Palette['primary'];
    alternate: Palette['primary'];
    planDraft: Palette['primary'];
    planIdea: Palette['primary'];
    planCompleted: Palette['primary'];
    planPublishing: Palette['primary'];
    planScheduled: Palette['primary'];
    coral: Palette['primary'];
  }

  interface PaletteOptions
  {
    positive?: PaletteOptions['primary'];
    negative?: PaletteOptions['primary'];
    lightPurple?: PaletteOptions['primary'];
    alternate?: PaletteOptions['primary'];
    planner?: PaletteOptions['primary'];
    planDraft?: PaletteOptions['primary'];
    planIdea?: PaletteOptions['primary'];
    planCompleted?: PaletteOptions['primary'];
    planPublishing?: PaletteOptions['primary'];
    planScheduled?: PaletteOptions['primary'];
    coral?: PaletteOptions['primary'];
  }
}

// Update the Button's color options to include custom colors
declare module '@mui/material/Button'
{
  interface ButtonPropsColorOverrides
  {
    positive: true;
    negative: true;
    alternate: true;
    planDraft: true;
    planIdea: true;
    planCompleted: true;
    planScheduled: true;
    coral: true;
  }
}

// https://mui.com/material-ui/customization/theming
export const theme = createTheme( {
  palette: {
    mode: 'light',
    text: {
      primary: '#000000',
      disabled: '#B4B3B3'
    },
    lightPurple: {
      main: 'rgba(247, 242, 247, .2)',
    },
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#F1F3F6',
      main: '#EEF1F4',
      dark: '#A6A8AA',
      contrastText: '#9BA5B7',
    },
    positive: {
      light: '#4FD8AF',
      main: '#23CF9C',
      dark: '#18906D',
      contrastText: '#fff',
    },
    negative: {
      light: '#ED6382',
      main: '#DB1B50',
      dark: '#A32A45',
      contrastText: '#fff',
    },
    alternate: {
      main: '#fff',
      contrastText: '#421FC8',
    },
    planDraft: {
      main: '#C54E74',
      light: '#F5EBEC',
    },
    planIdea: {
      main: '#625793',
      light: '#F1F1F6'
    },
    planCompleted: {
      main: '#4A569D',
      light: '#F1F1F6',
    },
    planPublishing: {
      main: '#16A49B',
      dark: '#0f726c',
      light: '#F1F1F6',
      contrastText: "#FFFFFF",
    },
    planScheduled: {
      main: '#FA585E',
      dark: '#AF3D41',
      light: '#F5EBEC',
      contrastText: "#FFFFFF",
    },
    coral: {
      main: "#F16874",
      dark: "#A84851",
      light: "#F3868F",
      contrastText: "#fff"
    },
  },
  spacing: 2,
} );

if ( ENABLE_ERROR_REPORTING )
{
  Sentry.init( ENV_CONFIG.sentryConfig );
}

if ( ENABLE_USAGE_TRACKING )
{
  gtagWrapper.initialize();
}

const root = ReactDOM.createRoot(
  document.getElementById( 'root' ) as HTMLElement
);

const router = createBrowserRouter( [
  {
    path: ROUTES.ROOT,
    element: <App/>,
    errorElement: <ErrorPage/>,
    children: [
      {
        index: true,
        element: <NavigateWithParams to={ROUTES.CHAT} replace/>
      },
      {
        path: ROUTES.CHAT,
        element: <PrivateRoute>
          <AssistantChat/>
        </PrivateRoute>,
      },
      {
        path: ROUTES.USER_SETTINGS,
        element: <PrivateRoute>
          <UserSettings/>
        </PrivateRoute>,
      },
      {
        path: ROUTES.MEDIA_LIBRARY,
        element: <PrivateRoute>
          <MediaLibraryTab/>
        </PrivateRoute>,
      },
      {
        path: ROUTES.PLAN,
        element: <PrivateRoute>
          <PlanTab/>
        </PrivateRoute>,
      },
      {
        path: ROUTES.SIGN_IN,
        element: <SignInLandingPage/>
      },
      {
        path: ROUTES.BRANDING,
        element: <PrivateRoute>
          <Branding/>
        </PrivateRoute>,
      },
      {
        path: ROUTES.SOCIAL_NETWORK_ACCOUNTS,
        element: <PrivateRoute>
          <SocialNetworkAccountsSection/>
        </PrivateRoute>,
      },
      {
        path: ROUTES.STYLE_QUIZ,
        element: <PrivateRoute>
          <StyleQuiz/>
        </PrivateRoute>,
      }
    ],
  },
] );

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <React.Fragment>
          <CssBaseline enableColorScheme/>
          <RouterProvider router={router}/>
        </React.Fragment>
      </ThemeProvider>
    </PersistGate>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
