import React, { useEffect } from 'react';
import './styles/App.scss';
import { Helmet } from "react-helmet";
import { APP_TAB_BAR_HEIGHT, FEATURE_FLAGS, ROUTE_SEO, ROUTES } from "./features/constants";
import { setFeatureFlagBoolean } from "./features/featureFlags/featureFlagsSlice";
import { each } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./app/store";
import { hasRemainingFreePosts, isUserLoggedIn, wasSubscribedUser } from "./features/user/userSlice";
import { FullPageMobileFooter } from "./features/layout/fullPageMobileFooter";
import { BottomNavigation, BottomNavigationAction, Link, Paper } from "@mui/material";
import { CustomTabPanel } from "./features/editing/customTabPanel";
import { FullPageMobileContent } from "./features/layout/fullPageMobileContent";
import SmsIcon from '@mui/icons-material/Sms';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { AppContainer } from "./features/layout/appContainer";
import { Outlet, useBeforeUnload, useLocation, useSearchParams } from "react-router-dom";
import { ErrorBoundary } from "@sentry/react";
import { eventTracker } from "./helpers/eventTracker";
import { chatServices } from "./services/chat.services";
import { ApplicationLevelLoader } from "./features/ui/applicationLevelLoader";
import { ApptimizeProvider } from "./features/apptimize/apptimizeProvider";
import { RemainingPostsBanner } from "./features/layout/remainingPostsBanner";
import { FullScreenUpsellDialog } from "./features/upsell/upsell";
import { getPaymentDialogOpen, getUpsellShown, setPaymentDialogOpen, setUpsellShown } from "./features/ui/uiSlice";
import { FullScreenPaymentDialog } from "./features/payment/paymentDialog";
import useNavigateWithSearchParams from "./features/hooks/useNavigateWithSearchParams";
import { EventTracking } from "./features/tracking/eventTracking";
import { authenticationServices } from "./services/authentication.services";
import { InAppNoticeBanners } from "./features/inAppNotices/InAppNoticeBanners";
import { setChannelTrackingParameters } from "./features/tracking/trackingSlice";
import { ManageSubscriptionBanner } from "./features/layout/manageSubscriptionBanner";
import FilterIcon from "@mui/icons-material/Filter";
import { getAbortController } from "./services/requestManager";
import { AfterAppInitializationSetup } from "./features/afterAppInitializationSetup/afterAppInitializationSetup";
import { setApplicationLoadingState } from "./features/loadingIndicator/loadingSlice";
import { StreakDialogFullScreen } from "./features/planner/streakDialog";
import { CurrentStreakAPI } from "./services/business.services";

function App()
{
  const APP_TAB_OUTLET_INDEX = 0;

  const location = useLocation();
  const navigateWithSearchParams = useNavigateWithSearchParams();

  const dispatch = useDispatch();
  const userIsLoggedIn = useSelector( ( state: RootState ) => isUserLoggedIn( state ) );
  const showManageSubscriptionBanner = useSelector( ( state: RootState ) => wasSubscribedUser( state ) );
  const showRemainingFreePosts = useSelector( ( state: RootState ) => hasRemainingFreePosts( state ) ) && !showManageSubscriptionBanner;
  const showUpsell = useSelector( ( state: RootState ) => getUpsellShown( state ) );
  const showPaymentDialog = useSelector( ( state: RootState ) => getPaymentDialogOpen( state ) );
  const [streak, setStreak] = React.useState<CurrentStreakAPI | undefined>( undefined );
  const [showStreakDialog, setStreakDialogShown] = React.useState( false );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  useBeforeUnload(
    React.useCallback( () =>
    {
      eventTracker.logAlkaiAppHidden();
      eventTracker.logAlkaiAppUnloaded();
    }, [] )
  );

  useEffect( () =>
  {
    if ( userIsLoggedIn )
    {
      refreshUserIfLoggedIn();
    }
  }, [userIsLoggedIn] );

  useEffect( () =>
  {
    each( FEATURE_FLAGS, ( flag ) =>
    {
      const flagValue = searchParams.get( flag );
      dispatch( setFeatureFlagBoolean( { [`${flag}`]: flagValue === "true" } ) );
    } );

    const queryParamsAsObject = Object.fromEntries(
      searchParams
    );

    eventTracker.initializeAmplitude(queryParamsAsObject.amplitude_device_id);
    updateChannelTrackingParameters( queryParamsAsObject )
    eventTracker.initializeGoogleAnalytics();
    eventTracker.logAlkaiAppLoaded( window.location.pathname, queryParamsAsObject )
    eventTracker.initializeFacebookPixel();
    eventTracker.initializeApptimizeParticipatedEvents();
    dispatch( setApplicationLoadingState( false ) );
    getAbortController();
  }, [] );

  function refreshUserIfLoggedIn()
  {
    authenticationServices.refreshUserIfLoggedIn().then( ( data ) =>
    {
      if ( !!data?.current_business.pending_streak )
      {
        setStreak( data.current_business.pending_streak );
        setStreakDialogShown( true );
      }
    } );
  }

  function updateChannelTrackingParameters( queryParams )
  {
    const { s, fbclid } = queryParams
    const channelTrackingParams = {
      alkai_source: s,
      fbclid,
    }

    dispatch( setChannelTrackingParameters( channelTrackingParams ) );
  }

  function handleNavigateToChat()
  {
    if ( !(location.pathname === ROUTES.CHAT) )
    {
      eventTracker.logChatMenuClicked();
    }
    navigateWithSearchParams( ROUTES.CHAT );
  }

  function handleNavigateToMediaLibrary()
  {
    if ( !(location.pathname === ROUTES.MEDIA_LIBRARY) )
    {
      chatServices.clearCheckMessagesTimeout();
      eventTracker.logMediaLibraryMenuClicked();
    }
    navigateWithSearchParams( ROUTES.MEDIA_LIBRARY );
  }

  function handleNavigateToPlan()
  {
    if ( !(location.pathname === ROUTES.PLAN) )
    {
      chatServices.clearCheckMessagesTimeout();
      eventTracker.logPlanMenuClicked();
    }
    navigateWithSearchParams( ROUTES.PLAN );
  }

  function handleNavigateToSettings()
  {
    if ( !(location.pathname === ROUTES.USER_SETTINGS) )
    {
      chatServices.clearCheckMessagesTimeout();
      eventTracker.logSettingsMenuClicked()
    }
    navigateWithSearchParams( ROUTES.USER_SETTINGS );
  }

  function handleUpsellClosed()
  {
    dispatch( setUpsellShown( false ) );
  }

  function handlePaymentDialogClosed()
  {
    dispatch( setPaymentDialogOpen( false ) );
  }

  function handleStreakDialogClose()
  {
    setStreakDialogShown( false );
  }

  return (
    <div className="alki-app">
      <ErrorBoundary>
        <ApptimizeProvider>
          <EventTracking/>
          <AfterAppInitializationSetup/>
          <AppContainer>
            <Helmet>
              <title>{ROUTE_SEO.ROOT.title}</title>
              <meta name="description" content={ROUTE_SEO.ROOT.description}/>
            </Helmet>

            {showRemainingFreePosts && <RemainingPostsBanner/>}
            {showManageSubscriptionBanner && <ManageSubscriptionBanner/>}
            <InAppNoticeBanners/>
            <FullScreenUpsellDialog manageOpenStateExternally={true}
                                    externalStateOpen={showUpsell}
                                    hideTopBar={true}
                                    handleClose={handleUpsellClosed}/>
            <FullScreenPaymentDialog manageOpenStateExternally={true}
                                     externalStateOpen={showPaymentDialog}
                                     hideTopBar={true}
                                     handleClose={handlePaymentDialogClosed}/>
            {showStreakDialog && streak && <StreakDialogFullScreen streak={streak}
                                                                   manageOpenStateExternally={true}
                                                                   externalStateOpen={showStreakDialog}
                                                                   workedAhead={true}
                                                                   hideTopBar={true}
                                                                   handleClose={handleStreakDialogClose}/>}
            <FullPageMobileContent>
              <CustomTabPanel value={APP_TAB_OUTLET_INDEX} index={APP_TAB_OUTLET_INDEX} sx={{ px: 0 }}>
                <Outlet/>
              </CustomTabPanel>
            </FullPageMobileContent>
            {userIsLoggedIn && <FullPageMobileFooter>
              <Paper elevation={3}>
                <BottomNavigation
                  sx={{ height: `${APP_TAB_BAR_HEIGHT}px` }}
                  showLabels
                  value={location.pathname}>
                  <BottomNavigationAction
                    label="Chat"
                    icon={<SmsIcon/>}
                    LinkComponent={Link}
                    onClick={handleNavigateToChat}
                    value={ROUTES.CHAT}
                  />
                  <BottomNavigationAction
                    label="Media"
                    icon={<FilterIcon/>}
                    LinkComponent={Link}
                    onClick={handleNavigateToMediaLibrary}
                    value={ROUTES.MEDIA_LIBRARY}
                  />
                  <BottomNavigationAction
                    label="Plan"
                    icon={<CalendarMonthIcon/>}
                    LinkComponent={Link}
                    onClick={handleNavigateToPlan}
                    value={ROUTES.PLAN}
                  />
                  <BottomNavigationAction
                    label="Settings"
                    icon={<ManageAccountsIcon/>}
                    onClick={handleNavigateToSettings}
                    value={ROUTES.USER_SETTINGS}
                  />
                </BottomNavigation>
              </Paper>
            </FullPageMobileFooter>}
          </AppContainer>
          <ApplicationLevelLoader/>
        </ApptimizeProvider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
