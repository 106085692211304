import Dialog from "@mui/material/Dialog";
import { Button, DialogActions, DialogTitle, Typography } from "@mui/material";
import * as React from "react";

export interface ConfirmDialogProps
{
  title: string;
  message?: string;
  confirmText?: string;
  cancelText?: string;
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export function ConfirmDialog( props: ConfirmDialogProps )
{

  function getConfirmText()
  {
    return props.confirmText || "Yes";
  }

  function getCancelText()
  {
    return props.cancelText || "No";
  }
  return (
    <>
      {props.open && <Dialog
        sx={{
          '& .MuiDialog-paper': { width: '90%', maxHeight: '100vh' },
          textAlign: "center",
        }}
        open={props.open}
      >
        <DialogTitle>{props.title}</DialogTitle>
        {!!props.message && <Typography sx={{ px: "24px", pb: "16px" }}>{props.message}</Typography>}
        <DialogActions sx={{ justifyContent: "center", px: "24px", pb: "16px"}}>
          <Button autoFocus variant="outlined" onClick={props.onCancel}>
            {getCancelText()}
          </Button>
          <Button
            variant="contained"
            onClick={props.onConfirm}>{getConfirmText()}</Button>
        </DialogActions>
      </Dialog>}
    </>
  );

}