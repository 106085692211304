import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import StarIcon from '@mui/icons-material/StarBorder';
import { PlanItemIconLabel } from "./PlanItemIconLabel";
import { BusinessHolidayAPI } from "../../services/business.services";
import { PlanAccordionHeader } from "./planAccordionHeader";
import { map } from "lodash";
import { HolidayCarouselLayoutPreview } from "./holidayCarouselLayoutPreview";
import { HolidayLayoutPreviewDialog } from "./holidayLayoutPreviewDialog";
import { HolidayLayoutDataAPI, holidayLayoutServices } from "../../services/holidayLayout.services";
import { PostIdeaDataAPI } from "../assistantChat/assistantChatSlice";
import { FullScreenEditor } from "../editing/editAndSharePostFlow";
import { PostIdeaContext } from "../context/postIdeaContext";
import { postIdeaServices } from "../../services/postIdeaServices";
import { addPostIdea } from "../postIdea/postIdeaSlice";
import { useDispatch } from "react-redux";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import { errorReporter } from "../error/errorReporter";
import { addPost } from "../postIdea/postsSlice";
import useNavigateWithSearchParams from "../hooks/useNavigateWithSearchParams";
import { ROUTE_PARAM, ROUTES } from "../constants";
import { parseISO } from "date-fns";
import { getInitialScheduleTimeForDraft } from "../postIdea/shareBatchHelper";

interface HolidayCarouselProps
{
  businessHoliday: BusinessHolidayAPI;
}

export function HolidayCarousel( props: HolidayCarouselProps )
{
  const dispatch = useDispatch();
  const navigateWithSearchParams = useNavigateWithSearchParams();
  const [showHolidayLayoutPreview, setShowHolidayLayoutPreview] = React.useState( false );
  const selectedHolidayLayoutRef = React.useRef<HolidayLayoutDataAPI | undefined>( undefined );
  const holidayPostIdeaRef = React.useRef<PostIdeaDataAPI | undefined>( undefined );
  const holidayScheduleDate = parseISO( props.businessHoliday.date );
  const [showEditor, setShowEditor] = React.useState( false );

  function getAccentColor()
  {
    return "planIdea.main";
  }

  function getLabel()
  {
    return "Holiday";
  }

  function getIcon()
  {
    return <PlanItemIconLabel label={getLabel()} color={getAccentColor()}>
      <StarIcon sx={{ color: "primary.contrastText", height: "15px", width: "15px" }}/>
    </PlanItemIconLabel>;
  }

  function getHolidayLayouts()
  {
    return props.businessHoliday.holiday_collection?.layouts;
  }

  async function handleHolidayLayoutSelected( slug: string )
  {
    const layoutData = await holidayLayoutServices.getHolidayLayoutData( slug );
    if ( selectedHolidayLayoutRef.current?.slug !== layoutData.slug )
    {
      holidayPostIdeaRef.current = undefined
    }
    selectedHolidayLayoutRef.current = layoutData;
    setShowHolidayLayoutPreview( true );
  }

  function handleClosePreview()
  {
    setShowHolidayLayoutPreview( false );
  }

  async function handleOpenEditorWithLayout( holidayLayout: HolidayLayoutDataAPI )
  {
    const holidayPostIdeaResult = await fetchPostIdea( holidayLayout );
    if ( !!holidayPostIdeaResult )
    {
      setShowEditor( true );
    }
  }

  async function fetchPostIdea( holidayLayout: HolidayLayoutDataAPI )
  {
    if ( !holidayPostIdeaRef.current )
    {
      try
      {
        const postIdeaData = await postIdeaServices.generateHolidayPostIdea( props.businessHoliday.id, holidayLayout.slug )
        holidayPostIdeaRef.current = postIdeaData;
        dispatch( addPostIdea( postIdeaData ) );
        await fetchPostJson( postIdeaData );
        return postIdeaData;
      }
      catch (error)
      {
        dispatch( setAlertMessage( errorAlert( "Could not load holiday post idea details.", 'top' ) ) );
        errorReporter.reportErrorToSentry( error );
      }
    }
    else
    {
      await fetchPostJson( holidayPostIdeaRef.current );
      return holidayPostIdeaRef.current;
    }
  }

  async function fetchPostJson( holidayPostIdea: PostIdeaDataAPI )
  {
    try
    {
      const postJson = await postIdeaServices.getPostJson( holidayPostIdea.id )
      dispatch( addPost( postJson ) );
    }
    catch (error)
    {
      dispatch( setAlertMessage( errorAlert( "Could not load holiday post details.", 'top' ) ) );
      errorReporter.reportErrorToSentry( error );
    }
  }

  function handleCloseFullScreenEditor()
  {
    setShowEditor( false );
    setShowHolidayLayoutPreview( false )
    navigateWithSearchParams( ROUTES.PLAN, { [ROUTE_PARAM.PLAN_TAB_REFRESH_REQUEST]: true } );
  }

  return <Box sx={{
    my: 5,
    backgroundColor: "planIdea.light",
    borderRadius: "5px",
    p: 0,
  }}>
    <PlanAccordionHeader icon={getIcon()}
                         accentColor={getAccentColor()}
                         expanded={false}
                         hideExpandIcon={true}>
    </PlanAccordionHeader>
    <Typography sx={{ fontSize: "14px", textAlign: "left", fontWeight: "bold", ml: 5, mb: 5 }}>{props.businessHoliday.holiday_name}</Typography>
    <Stack sx={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      overflowY: "hidden",
      height: 145,
      gap: 5,
      px: 5,
      mx: 5,
    }}>
      {
        map( getHolidayLayouts(), ( layout, index ) =>
          <HolidayCarouselLayoutPreview key={index} layout={layout} handleHolidayLayoutSelected={handleHolidayLayoutSelected}/>
        )
      }
    </Stack>
    {!!selectedHolidayLayoutRef.current && <HolidayLayoutPreviewDialog open={showHolidayLayoutPreview} handleClose={handleClosePreview}
                                                                       holidayLayout={selectedHolidayLayoutRef.current}
                                                                       handleLayoutSelected={handleOpenEditorWithLayout}/>}

    {!!holidayPostIdeaRef.current && <PostIdeaContext.Provider value={{ postIdea: holidayPostIdeaRef.current }}>
      <FullScreenEditor editable={true}
                        manageOpenStateExternally={true}
                        externalStateOpen={showEditor}
                        handleClose={handleCloseFullScreenEditor}
                        initialScheduleForDate={getInitialScheduleTimeForDraft(holidayScheduleDate)}
                        hideTopBar={true}/>
    </PostIdeaContext.Provider>}
  </Box>
}
