import { Box, Tooltip } from "@mui/material";
import { merge } from "lodash";
import { LAYOUT_BUTTON_HEIGHT_PX, LAYOUT_BUTTON_SELECTED_HEIGHT_PX } from "../../constants";
import * as React from "react";
import { isDevModeEnabled } from "../../featureFlags/featureFlagsSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { HolidayLayoutPreviewAPI } from "../../../services/business.services";

export interface HolidayLayoutProps
{
  holidayLayout: HolidayLayoutPreviewAPI;
  handleLayoutSelected: ( holidayLayout: HolidayLayoutPreviewAPI ) => void;
  selected: boolean;
}

export function HolidayLayout( props: HolidayLayoutProps )
{
  const { holidayLayout } = props;
  const devModeEnabled = useSelector( ( state: RootState ) => isDevModeEnabled( state ) );

  const handleOnClick = ( event: React.MouseEvent ) =>
  {
    props.handleLayoutSelected( holidayLayout );
  }
  const selectionScale = LAYOUT_BUTTON_SELECTED_HEIGHT_PX / LAYOUT_BUTTON_HEIGHT_PX;
  const className = props.selected ? "selected" : "";

  const imageSxProps = { height: "100%",
    transform: props.selected ? `scale(${selectionScale})` : "unset",
    transition: "all 0.1s" };
  const holidayImagePreview = <Box component="img" sx={imageSxProps} src={props.holidayLayout.image_url}></Box>;
  return (
    <Box
      className={className}
      sx={merge( {
        height: LAYOUT_BUTTON_HEIGHT_PX,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        px: 8,
        py: 4,
      } )} onClick={handleOnClick}>
      {devModeEnabled && <Tooltip title={holidayLayout.slug}>
        {holidayImagePreview}
      </Tooltip>}
      {!devModeEnabled && holidayImagePreview}
    </Box>
  )
}
