import { Button, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import { AutoAwesome } from "@mui/icons-material";
import { HolidayLayoutDataAPI } from "../../services/holidayLayout.services";
import { PostPreview } from "../postIdea/postPreview";
import { convertPost } from "../postIdea/postsSlice";

export interface HolidayLayoutPreviewDialogProps
{
  open: boolean;
  handleClose: () => void;
  holidayLayout: HolidayLayoutDataAPI;
  handleLayoutSelected: ( layout: HolidayLayoutDataAPI ) => Promise<void>;
}

export function HolidayLayoutPreviewDialog( props: HolidayLayoutPreviewDialogProps )
{
  const [generationInProgress, setGenerationInProgress] = React.useState<boolean>( false );

  function handleGenerate()
  {
    setGenerationInProgress( true );
    props.handleLayoutSelected( props.holidayLayout ).then( () =>
    {
      setGenerationInProgress( false );
    } );
  }

  return (<Dialog
    fullWidth={false}
    maxWidth={"sm"}
    open={props.open}
  >
    <DialogTitle sx={{ pb: 0, pt: 14, textAlign: "center", fontSize: "20px" }}>Holiday Template Preview</DialogTitle>
    <IconButton
      aria-label="close"
      onClick={props.handleClose}
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: ( theme ) => theme.palette.grey[500],
      }}
    >
      <CloseIcon/>
    </IconButton>
    <DialogContent sx={{pt:4}}>
      <Stack spacing={5}>
        <PostPreview iframeId={"holiday-layout-preview-" + props.holidayLayout.slug}
                     post={convertPost( props.holidayLayout.post_json )}
                     className={"holiday-layout-preview"}
                     showSafeArea={false}
                     showInstagramOverlay={false}/>
        <Stack direction={"row"} spacing={2} justifyContent="space-between">
          <Typography fontWeight={"bold"} fontSize={16} alignSelf={"center"}>{props.holidayLayout.format_description}</Typography>
          <Button startIcon={<AutoAwesome/>} disabled={generationInProgress} variant={"contained"} onClick={handleGenerate}>Generate</Button>
        </Stack>
      </Stack>
    </DialogContent>
  </Dialog>)
}
