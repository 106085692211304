import { Box } from "@mui/material";
import React from "react";
import { HolidayLayoutPreviewAPI } from "../../services/business.services";

export interface HolidayCarouselLayoutPreviewProps
{
  layout: HolidayLayoutPreviewAPI;
  handleHolidayLayoutSelected: ( slug: string ) => void;
}

export function HolidayCarouselLayoutPreview( props: HolidayCarouselLayoutPreviewProps )
{
  function handleSelected()
  {
    props.handleHolidayLayoutSelected( props.layout.slug );
  }

  return <Box sx={{ height: 130, cursor: "pointer" }} onClick={handleSelected}>
    <img src={props.layout.image_url} alt="holiday" height="100%"/>
  </Box>
}