import { Box, Stack, Typography } from "@mui/material";
import * as React from "react";
import { DateUtils } from "../utils/dateUtils";
import { CalendarIcon } from "@mui/x-date-pickers";

interface DateHeaderProps
{
  date: Date;
  holidayName?: string;
}

export function DateHeader( props: DateHeaderProps )
{
  function getDisplayDate()
  {
    const displayDate = DateUtils.getLongFormattedDisplayDate( props.date );
    if ( DateUtils.isToday( props.date ) )
    {
      return <span><b>Today</b> - {displayDate}</span>;
    }
    else if ( DateUtils.isTomorrow( props.date ) )
    {
      return <span><b>Tomorrow</b> - {displayDate}</span>;
    }
    else
    {
      return displayDate;
    }
  }

  return <Box sx={{
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    my: 5,
  }}>
    <Stack>
      <Typography variant="body1" sx={{ mr: 5, textColor: "black" }}>{getDisplayDate()}</Typography>
      {props.holidayName &&
       <Stack direction={"row"} sx={{ alignItems: "center" }} spacing={2}>
         <CalendarIcon sx={{ color: "black", height: "15px", width: "15px" }}/>
         <Typography variant="caption" sx={{ textAlign: "left", textColor: "black" }}>{props.holidayName}</Typography>
       </Stack>}
    </Stack>
  </Box>
}
