import { getToApi } from "./requestManager";
import { concat } from "lodash";

export const outputFormatServices = {
  getOutputFormats,
}

const OUTPUT_FORMATS_BASE_PATH = "output_formats";
const OUTPUT_FORMATS_LIST_ENDPOINT = "list"

export interface OutputFormatAPI
{
  id: string;
  display_name: string;
}

export interface OutputFormatsListAPI
{
  output_formats: OutputFormatAPI[],
}

async function getOutputFormats()
{
  try
  {
    const endpointUrl = buildUrl( [OUTPUT_FORMATS_LIST_ENDPOINT] );
    return await getToApi<OutputFormatsListAPI>( endpointUrl );
  }
  catch (error)
  {
    return Promise.reject( "Could not fetch output formats" );
  }
}

function buildUrl( pathPieces: string[] )
{
  return concat( [OUTPUT_FORMATS_BASE_PATH], pathPieces ).join( "/" );
}
