import { Box } from "@mui/material";
import { ReactNode } from "react";

export interface PostEditingDrawerHorizontalListProps
{
  children: ReactNode;
  scrollContentDivRef: React.MutableRefObject<HTMLDivElement | undefined>;
  scrollContentClass: React.MutableRefObject<string>;
}

export function PostEditingDrawerHorizontalList( props: PostEditingDrawerHorizontalListProps )
{
  return (
    <Box ref={props.scrollContentDivRef}
         className={props.scrollContentClass.current}
         sx={{ display: "flex", height: "100%", flexDirection: "column", flexWrap: "wrap", justifyContent: "space-around"}}>
      {props.children}
    </Box>
  );
}
