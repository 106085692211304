import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, CircularProgress, Drawer } from "@mui/material";
import ProgressOverlay from "../loadingIndicator/progressOverlay";
import { MediaAssetAPI } from "../../services/business.services";
import { useDispatch } from "react-redux";
import { cloneDeep, filter, findIndex, forEach, map, size } from "lodash";
import * as InfiniteScroll from "react-infinite-scroller";
import UserMediaLibraryItem from "./userMediaLibraryItem";
import SendIcon from "@mui/icons-material/Send";
import { clearAlertMessage, errorAlert, setAlertMessage } from "../alert/alertSlice";
import { MediaPreviewDialog } from "./mediaPreviewDialog";
import { MediaPickingMode } from "../constants";

export interface AddFromMediaLibraryGridProps
{
  mediaAssets: MediaAssetAPI[];
  currentMediaUrls?: string[];
  preselectedMediaAssets?: MediaAssetAPI[];
  uploading?: boolean;
  buttonText: string;
  loadMedia?: ( page: number ) => void;
  hasMore?: () => boolean;
  handleChooseMediaAssets: ( mediaAssets: MediaAssetAPI[] ) => void;
  handleDeleteMedia?: ( mediaAsset: MediaAssetAPI ) => void;
  maxMediaCount: number;
  maxMediaCountError: string;
  logoPicking?: boolean;
  previewMediaInsteadOfSelect?: boolean;
  mediaPickingMode?: MediaPickingMode;
}

function AddFromMediaLibraryGrid( props: AddFromMediaLibraryGridProps )
{
  const dispatch = useDispatch();
  const [selectedMediaAssets, setSelectedMediaAssets] = useState<MediaAssetAPI[]>( props.preselectedMediaAssets || [] );
  const [uploading, setUploading] = useState( false );
  const isInReplaceMediaMode = props.mediaPickingMode === MediaPickingMode.ReplaceSingle;
  const currentMediaUrls = isInReplaceMediaMode ? [] : props.currentMediaUrls;
  const maxMediaCount = isInReplaceMediaMode ? 1 : props.maxMediaCount;
  const mediaAssetNumberingOffset = !!currentMediaUrls ? size( currentMediaUrls ) : undefined;
  const [mediaAssetToPreview, setMediaAssetToPreview] = useState<MediaAssetAPI>();

  useEffect( () =>
  {
    if ( props.preselectedMediaAssets )
    {
      forEach( props.preselectedMediaAssets, ( mediaAsset ) =>
      {
        addToSelectedMedia( mediaAsset );
      } );
    }
  }, [props.preselectedMediaAssets] );

  function handleMediaClicked( mediaAsset: MediaAssetAPI )
  {
    if ( props.previewMediaInsteadOfSelect )
    {
      setMediaAssetToPreview( mediaAsset );
    }
    else
    {
      toggleMediaClicked( mediaAsset );
    }
  }

  const toggleMediaClicked = ( mediaAsset: MediaAssetAPI ) =>
  {
    dispatch( clearAlertMessage() );

    const mediaIsSelected = indexOfSelectedMedia( mediaAsset ) > -1;

    if ( isInReplaceMediaMode )
    {
      props.handleChooseMediaAssets( [mediaAsset] );
    }
    else if ( mediaIsSelected )
    {
      const newSelection = filter( selectedMediaAssets, ( selectedMediaLibraryAsset ) => selectedMediaLibraryAsset.id !== mediaAsset.id );
      setSelectedMediaAssets( newSelection );
    }
    else if ( !canSelectMoreMedia( selectedMediaAssets ) )
    {
      dispatch( setAlertMessage( errorAlert( props.maxMediaCountError ) ) );
    }
    else
    {
      if ( props.logoPicking )
      {
        props.handleChooseMediaAssets( [mediaAsset] );
      }
      else
      {
        addToSelectedMedia( mediaAsset );
      }
    }
  }

  const addToSelectedMedia = ( mediaAsset: MediaAssetAPI ) =>
  {
    setSelectedMediaAssets( ( prevState ) =>
    {
      if ( canSelectMoreMedia( prevState ) )
      {
        return [...prevState, mediaAsset]
      }
      return [...prevState];
    } );
  }

  const canSelectMoreMedia = ( currentMediaLibraryAssets: MediaAssetAPI[] ) =>
  {
    if ( !!currentMediaUrls )
    {
      return size( currentMediaLibraryAssets ) + currentMediaUrls.length < maxMediaCount
    }
    return true;
  }

  const indexOfSelectedMedia = ( mediaAsset: MediaAssetAPI ) =>
  {
    return findIndex( selectedMediaAssets, ( mediaAssetIteration ) => mediaAssetIteration.id === mediaAsset.id );
  }

  const handleChooseSelectedMediaLibraryAssets = async () =>
  {
    setUploading( true );
    const mediaAssetObjects = cloneDeep( selectedMediaAssets );
    clearSelection()
    try
    {
      props.handleChooseMediaAssets( mediaAssetObjects );
    }
    catch (error)
    {
      return Promise.reject( "Failed when calling handleChooseMediaAssets." );
    }
    finally
    {
      setUploading( false );
    }
  }

  const clearSelection = () =>
  {
    setSelectedMediaAssets( [] );
  }

  const handleCancelSelection = () =>
  {
    setSelectedMediaAssets( [] );
  }

  const deleteMediaClicked = async ( mediaAsset: MediaAssetAPI ) =>
  {
    if ( props.handleDeleteMedia )
    {
      const mediaIsSelected = !props.previewMediaInsteadOfSelect && indexOfSelectedMedia( mediaAsset ) > -1;
      if ( mediaIsSelected )
      {
        toggleMediaClicked( mediaAsset );
      }

      setUploading( true );

      await props.handleDeleteMedia( mediaAsset );
      setUploading( false );
    }
  }

  const defaultLoadMore = ( page: number ) =>
  {
    return null;
  }

  function handleCloseMediaPreview()
  {
    setMediaAssetToPreview( undefined );
  }

  return (
    <>
      {/*
          // @ts-ignore */}
      <InfiniteScroll
        pageStart={1}
        element={'ul'}
        style={{
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: "4px",
          display: "grid",
          listStyle: "none",
          padding: 0,
          maxWidth: "321px",
          margin: "0 auto",
        }}
        loadMore={props.loadMedia || defaultLoadMore}
        hasMore={props.hasMore && props.hasMore()}
        loader={<CircularProgress key="progress" size={30}/>}
        useWindow={false}
      >

        {map( props.mediaAssets, ( mediaAsset: MediaAssetAPI ) =>
        {
          return <UserMediaLibraryItem key={mediaAsset.id}
                                       mediaAsset={mediaAsset}
                                       mediaAssetNumberingOffset={mediaAssetNumberingOffset}
                                       selectedMediaAssets={selectedMediaAssets}
                                       showDelete={!!props.handleDeleteMedia}
                                       handleMediaClicked={handleMediaClicked}
                                       deleteMediaClicked={deleteMediaClicked}
                                       renderForLogoPicker={props.logoPicking}
          />
        } )}
      </InfiniteScroll>

      {!!mediaAssetToPreview?.url && <MediaPreviewDialog open={!!mediaAssetToPreview?.url}
                                                         onClose={handleCloseMediaPreview}
                                                         mediaUrl={mediaAssetToPreview?.url || ""}/>}
      <Drawer
        anchor={"bottom"}
        open={size( selectedMediaAssets ) > 0}
        variant="persistent">
        <Button
          variant='contained'
          color='primary'
          endIcon={<SendIcon/>}
          onClick={handleChooseSelectedMediaLibraryAssets}>
          {props.buttonText}
        </Button>
        <Button
          variant='outlined'
          color='primary'
          onClick={handleCancelSelection}>
          Cancel
        </Button>
      </Drawer>
      {(props.uploading || uploading) && <ProgressOverlay/>}
    </>
  );
}

export default AddFromMediaLibraryGrid;
