import * as React from "react";
import { Box, Drawer, SxProps } from "@mui/material";
import { merge } from "lodash";
import { EditTab, Z_INDEX_DRAWER } from "../constants";
import { LayoutDrawer } from "./layout/layoutDrawer";
import { BrandSettingsDrawer } from "../brandStyle/brandSettingsDrawer";
import { PostIdeaColorPaletteDrawer } from "./colors/postIdeaColorPaletteDrawer";
import { PostIdeaFontSetDrawer } from "./fonts/postIdeaFontSetDrawer";
import { HolidayLayoutDrawer } from "./holidayLayout/holidayLayoutDrawer";

interface DrawerPanelProps
{
  children?: React.ReactNode;
  tabId: EditTab;
  selectedTab: EditTab;
  sx?: SxProps;
  handleDrawerClose: () => void;
}

export function CustomDrawerPanel( props: DrawerPanelProps )
{
  const { children, selectedTab, tabId, sx, handleDrawerClose, ...other } = props;

  const defaultSxProps: SxProps = {
    textAlign: 'center',
    m: 1,
    zIndex: Z_INDEX_DRAWER,
    ".MuiModal-backdrop": { backgroundColor: "rgba(0,0,0,0)" },
    ".MuiPaper-root": { borderTopLeftRadius: "20px", borderTopRightRadius: "20px" }
  }

  const mergedSx = merge( defaultSxProps, sx );

  const shouldHide = selectedTab !== tabId;

  const onClose = () =>
  {
    handleDrawerClose();
  }

  return (
    <>
      <Drawer
        anchor="bottom"
        open={!shouldHide}
        sx={mergedSx}
        onClose={onClose}
        {...other}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          {tabId === EditTab.Layout && <LayoutDrawer handleDrawerClose={handleDrawerClose}/>}
          {tabId === EditTab.HolidayLayouts && <HolidayLayoutDrawer handleDrawerClose={handleDrawerClose}/>}
          {tabId === EditTab.Colors && <PostIdeaColorPaletteDrawer handleDrawerClose={handleDrawerClose}/>}
          {tabId === EditTab.Fonts && <PostIdeaFontSetDrawer handleDrawerClose={handleDrawerClose}/>}
          {tabId === EditTab.Brand && <BrandSettingsDrawer handleDrawerClose={handleDrawerClose}/>}
        </Box>
      </Drawer>
    </>
  );
}
