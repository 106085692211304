import * as React from 'react';
import { Box } from "@mui/material";
import { MediaItem } from "../mediaList/mediaItem";
import VideocamIcon from '@mui/icons-material/Videocam';
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import { indexOf } from "lodash";
import { UrlUtils } from "../utils/urlUtils";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities';
import { browserUtils } from "../utils/browserUtils";
import TransparentGridTile from "../../assets/transparent-grid.png";

export interface SortableDesignMediaItemProps
{
  orderedMediaUrls: string[];
  url: string;
  manageMediaClicked?: ( url: string, mediaIndex: number ) => void;
}

function SortableDesignMediaItem( props: SortableDesignMediaItemProps )
{
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable( { id: props.url } );

  const modifiedUrlWithContext = new UrlUtils().modifyUrlWithContextToFixCrossOriginIssues( props.url, "mediaList" );
  const isMobileBrowser = browserUtils.isMobileBrowser();
  const background = mediaPotentiallyHasTransparency() ? `url(${TransparentGridTile}) repeat` : "white";

  function mediaPotentiallyHasTransparency()
  {
    return new UrlUtils().isFileTypeThatSupportsTransparency( props.url )
  }

  const makeEndItem = () =>
  {
    return <Box>
      {makeManageButton()}
      {makeVideoIndicator()}
    </Box>
  }

  const makeManageButton = () =>
  {
    if ( !!props.manageMediaClicked )
    {
      return (<Box sx={{ position: "absolute", top: 0, right: 0, background: "rgba(0,0,0,.4)", borderRadius: "100%" }}>
          <IconButton onClick={handleManageMediaClicked} sx={{ color: "white", padding: 2 }}><SettingsIcon sx={{ fontSize: "15px" }}/></IconButton>
        </Box>
      );
    }
    return null;
  }

  const handleManageMediaClicked = ( e: React.MouseEvent ) =>
  {
    e.stopPropagation();
    if ( !!props.manageMediaClicked )
    {
      props.manageMediaClicked( props.url, indexOf( props.orderedMediaUrls, props.url ) );
    }
  }

  const makeVideoIndicator = () =>
  {
    if ( new UrlUtils().isVideoUrl( props.url ) )
    {
      return (
        <Box sx={{ position: "absolute", bottom: 0, right: 0, background: "rgba(0,0,0,.4)", display: "flex" }}>
          <VideocamIcon sx={{ padding: 0, color: "white" }}/>
        </Box>);
    }
    return null;
  }

  let dragListenersOnParent = {}
  if ( !isMobileBrowser )
  {
    // https://stackoverflow.com/questions/74456128/dnd-kit-sortable-list-with-drag-handle-setactivatornoderef-has-no-effect
    // only apply drag listener to sortable item parent on desktop, otherwise use drag handle for mobile
    dragListenersOnParent = { ...attributes, ...listeners }
  }

  return (
    <div ref={setNodeRef}
         style={{
           transform: CSS.Transform.toString( transform ),
           transition,
           WebkitTouchCallout: "none",
           WebkitUserSelect: "none",
           MozUserSelect: "none",
           userSelect: "none",
           position: "relative",
         }}
         {...dragListenersOnParent}
    >
      <MediaItem
        // @ts-ignore
        additionalStyle={{ "--aspect-ratio": (9 / 16).toFixed( 2 ), width: "107px" }}
        sxProps={{
          background: background,
          "& .mediaListItem": { height: "100%" },
          "& .mediaListItem.MuiImageListItem-img": { objectFit: "cover" },
          "& .mediaListItem.mediaListItemVideo": {
            width: "100%",
            objectFit: "cover"
          }
        }}
        key={props.url}
        url={modifiedUrlWithContext}
        removable={false}
        endItem={makeEndItem()}
      />
      {isMobileBrowser && <Box sx={{ position: "absolute", top: 0, left: 0 }}>
        <IconButton {...attributes} {...listeners}><DragIndicatorIcon sx={{ padding: 0, color: "white" }}/></IconButton>
      </Box>}
    </div>);
}

export default SortableDesignMediaItem;
