import * as React from 'react';
import { Stack, Typography } from "@mui/material";
import "./dateTimePicker.scss";

export function SocialNetworkAccountsBadBrowserWall()
{

  return <Stack sx={{
    position: 'absolute', background: "rgba(255,255,255,.7)",
    backdropFilter: "blur(2px)", top: 0, left: -10, right: -10, bottom: 0,
  }} spacing={5}>
    <Typography sx={{ color: "black", textAlign: "center" }} variant={"subtitle2"}>To connect your social accounts, first open Alkai in
                                                                                   an external browser.</Typography>
    <Typography sx={{ color: "black", textAlign: "center" }} variant={"subtitle2"}>Tap on the 3 dots in the top right of the screen to
                                                                                   open an external browser.</Typography>
  </Stack>
}