import {
  Accordion,
  AccordionDetails,
  Box,
  Button,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import DeleteIcon from "@mui/icons-material/Delete";
import {
  businessServices,
  PLAN_SUGGESTION_STATUS_FAILED_TO_GENERATE_POST_IDEA,
  PLAN_SUGGESTION_STATUS_QUEUED,
  PostingPlanDateAPI
} from "../../services/business.services";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { eventTracker } from "../../helpers/eventTracker";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { isGuestUser, shouldBlockForUpsell } from "../user/userSlice";
import { setUpsellShown } from "../ui/uiSlice";
import { UPSELL_SOURCE_GENERATE_PLAN_IDEA } from "../../helpers/trackingConstants";
import { PlanItemIconLabel } from "./PlanItemIconLabel";
import { PlanAccordionSummary } from "./planAccordionSummary";
import { isToday } from "date-fns";
import { LoadingButton } from "@mui/lab";
import EditIcon from "@mui/icons-material/Edit";
import { AutoAwesome } from "@mui/icons-material";
import { PlanItemCtaFooterContainer } from "./planItemCTAFooterContainer";
import { PlanIdeaTitleTypography } from "./planIdeaTitleTypography";
import { errorAlert, setAlertMessage } from "../alert/alertSlice";
import { KEYBOARD_ENTER_KEY_ID } from "../constants";
import { map } from "lodash";
import { apptimizeVariables } from "../apptimize/apptimizeVariables";
import { OutputFormatAPI, outputFormatServices } from "../../services/outputFormat.services";

interface PlanIdeaFormProps
{
  postingPlanDate?: PostingPlanDateAPI;
  requestRemovePlannedPost: ( postingPlanDate?: PostingPlanDateAPI ) => void;
  requestSavePlannedPost: ( postIdeaTitle: string, outputFormatId?: string, postingPlanDateId?: string ) => void;
  date: Date;
  handleUpdatePlannerData: () => void;
}

export function PlanIdeaForm( props: PlanIdeaFormProps )
{
  const dispatch = useDispatch();
  const planSuggestion = props.postingPlanDate?.plan_suggestion;

  const isGuest = useSelector( ( state: RootState ) => isGuestUser( state ) );
  const [saveButtonEnabled, setSaveButtonEnabled] = useState<boolean>( false );
  const savedPostIdeaTitle = planSuggestion?.post_idea_title || props.postingPlanDate?.post_idea_title;
  const savedOutputFormatId = props.postingPlanDate?.output_format?.id;

  const shouldSupportOutputFormats = apptimizeVariables.supportsOutputFormats();
  const [outputFormats, setOutputFormats] = useState<OutputFormatAPI[]>();
  const [selectedOutputFormatId, setSelectedOutputFormatId] = useState<string|undefined>(savedOutputFormatId);

  const [postIdeaInputText, setPostIdeaInputText] = useState<string>( savedPostIdeaTitle || "" );
  const [expanded, setExpanded] = useState<boolean>( !props.postingPlanDate );
  const [loadingIdea, setLoadingIdea] = useState<boolean>( false );
  const blockForUpsell = useSelector( ( state: RootState ) => shouldBlockForUpsell( state ) );
  const isNewOrChangedUnsavedTitle = !savedPostIdeaTitle || (!!savedPostIdeaTitle && hasDifferentInputTextThanSaved( postIdeaInputText ));
  const isNewOrChangedOutputFormatId = savedOutputFormatId !== selectedOutputFormatId;
  const postIdeaInputRef = useRef<HTMLInputElement>( null );
  const [pregeneratePostLoading, setPregeneratePostLoading] = useState<boolean>( false );
  const draftGenerationInProgress = planSuggestion?.status === PLAN_SUGGESTION_STATUS_QUEUED
                                    && !planSuggestion.post_idea_id;

  function hasDifferentInputTextThanSaved( newText: string )
  {
    return newText !== savedPostIdeaTitle && newText.length > 0;
  }

  useEffect( () =>
  {
    if ( shouldSupportOutputFormats && expanded )
    {
      getAllOutputFormats();
    }
    if ( postIdeaInputRef.current && expanded )
    {
      postIdeaInputRef.current.focus();
    }
  }, [expanded] );

  useEffect( () =>
  {
    setSaveButtonEnabled( hasDifferentInputTextThanSaved( postIdeaInputText ) );
  }, [props.postingPlanDate?.post_idea_title] );

  async function getAllOutputFormats()
  {
    const outputFormatsListResponse = await outputFormatServices.getOutputFormats();
    setOutputFormats( outputFormatsListResponse.output_formats );
  }

  function getDraftGenerationText()
  {
    if ( draftGenerationInProgress )
    {
      return "Draft is being generated";
    }
    return "Draft will be generated at 9:00 AM";
  }

  const handleDeleteClick = () =>
  {
    eventTracker.logPlanIdeaDeleteClicked( props.date, props.postingPlanDate?.post_idea_title, !!props.postingPlanDate );
    props.requestRemovePlannedPost( props.postingPlanDate );
  }

  const handleSaveClick = () =>
  {
    setExpanded( false );
    eventTracker.logPlanIdeaSaveClicked( props.date, postIdeaInputText, !!props.postingPlanDate );
    props.requestSavePlannedPost( postIdeaInputText, selectedOutputFormatId, props.postingPlanDate?.id );
  }

  const handleAccordionClicked = () =>
  {
    if ( allowedToCollapse() )
    {
      if ( !expanded && draftGenerationInProgress )
      {
        return;
      }

      setExpanded( !expanded );
    }
  }

  const allowedToCollapse = () =>
  {
    return !!props.postingPlanDate;
  }

  const handleGenerateIdea = () =>
  {
    if ( blockForUpsell )
    {
      dispatch( setUpsellShown( true ) );
      eventTracker.logUpsellShown( UPSELL_SOURCE_GENERATE_PLAN_IDEA );
      return;
    }

    eventTracker.logPlanIdeaGenerateIdeaClicked( props.date, props.postingPlanDate?.post_idea_title )
    setLoadingIdea( true )
    businessServices.getGeneratedPlanIdeaTitleForDate( props.date ).then( ( response ) =>
    {
      setPostIdeaInputText( response.plan_idea_title );
      setSaveButtonEnabled( true );
    } ).finally( () =>
    {
      setLoadingIdea( false )
    } );
  }

  function getIcon()
  {
    return <PlanItemIconLabel label={"Idea"} color={getAccentColor()}>
      <LightbulbOutlinedIcon sx={{ color: "primary.contrastText", height: "15px", width: "15px" }}/>
    </PlanItemIconLabel>;
  }

  function getAccentColor()
  {
    return "planIdea.main";
  }

  async function pregeneratePost( e: React.MouseEvent )
  {
    setPregeneratePostLoading( true );

    e.stopPropagation();

    eventTracker.logGeneratePostClicked( props.date, props.postingPlanDate?.post_idea_title );
    if ( !!props.postingPlanDate )
    {
      const formattedDate = props.postingPlanDate.date;

      try
      {
        const data = await businessServices.pregenerateSinglePlanSuggestion( formattedDate )
        if ( data.success )
        {
          await props.handleUpdatePlannerData();
        }
        else
        {
          dispatch( setAlertMessage( errorAlert( "An error occurred. Please try again." ) ) );
        }
      }
      catch (error)
      {
        dispatch( setAlertMessage( errorAlert( "An error occurred. Please try again." ) ) );
      }

      setPregeneratePostLoading( false );
    }
  }

  function getDraftPostButton()
  {
    if ( !isToday( props.date ) )
    {
      let buttonText = "Generate";
      if ( hasFailedBeforePostIdeaCreated() )
      {
        buttonText = "Try again"
      }
      return <LoadingButton onClick={pregeneratePost}
                            startIcon={<AutoAwesome/>}
                            disabled={draftGenerationInProgress}
                            loading={pregeneratePostLoading}
                            variant="contained"
                            sx={{ cursor: draftGenerationInProgress ? "default" : "pointer" }}
      >{buttonText}</LoadingButton>;
    }
    return null;
  }

  function planCtaFooter()
  {
    if ( expanded )
    {
      return planCTAFooterWhenExpanded();
    }
    return planCTAFooterWhenCollapsedExpanded();
  }

  function planCTAFooterWhenExpanded()
  {
    return <PlanItemCtaFooterContainer>
      <Button variant="outlined"
              color="negative"
              startIcon={<DeleteIcon/>}
              onClick={handleDeleteClick}
      >Delete</Button>
      <Button
        startIcon={<CheckCircleOutlineIcon/>}
        variant="contained"
        disabled={!saveButtonEnabled}
        onClick={handleSaveClick}
      >{(isNewOrChangedUnsavedTitle || isNewOrChangedOutputFormatId) ? "Save Idea" : "Saved"}</Button>
    </PlanItemCtaFooterContainer>;
  }

  function planCTAFooterWhenCollapsedExpanded()
  {
    return <PlanItemCtaFooterContainer>
      <Button variant="outlined"
              disabled={draftGenerationInProgress}
              startIcon={<EditIcon/>}
              onClick={handleAccordionClicked}
      >Edit</Button>
      {getDraftPostButton()}
    </PlanItemCtaFooterContainer>;
  }

  function postIdeaTitle()
  {
    let statusMessage: string | null = null;
    const hasFailedStatus = hasFailedBeforePostIdeaCreated();

    if ( draftGenerationInProgress )
    {
      statusMessage = "I'll notify you when it's ready!";
      if ( isGuest )
      {
        statusMessage = "Refresh this page in a few minutes to view or sign up to be notified when it's ready."
      }
    }
    else if ( hasFailedStatus )
    {
      statusMessage = "Oops! Something went wrong and the post failed to generate.";
    }
    return <Stack>
      <PlanIdeaTitleTypography>{savedPostIdeaTitle}</PlanIdeaTitleTypography>
      {shouldSupportOutputFormats && <PlanIdeaTitleTypography
        sx={{ fontStyle: "italic" }}>Output format: {props.postingPlanDate?.output_format?.display_name} </PlanIdeaTitleTypography>}
      {statusMessage && <PlanIdeaTitleTypography sx={{ mt: 5 }}>{statusMessage}</PlanIdeaTitleTypography>}
    </Stack>;
  }

  function hasFailedBeforePostIdeaCreated()
  {
    return planSuggestion?.status === PLAN_SUGGESTION_STATUS_FAILED_TO_GENERATE_POST_IDEA;
  }

  function handleKeyDown( event: { which: number; preventDefault: () => void; } )
  {
    if ( event.which === KEYBOARD_ENTER_KEY_ID )
    {
      event.preventDefault();
      handleSaveClick();
    }
  }

  function handleOutputFormatChange( event: SelectChangeEvent )
  {
    setSelectedOutputFormatId( event.target.value );
    setSaveButtonEnabled( true );
  }

  return <Box sx={{ mb: 5 }}>
    <Accordion
      expanded={expanded}
      sx={{
        backgroundColor: "planIdea.light",
        borderRadius: "5px",
        p: 0,
      }}>
      <PlanAccordionSummary
        onClick={handleAccordionClicked}
        icon={getIcon()}
        savedPostIdeaTitle={postIdeaTitle()}
        accentColor={getAccentColor()}
        helperText={getDraftGenerationText()}
        expanded={expanded}
        hideExpandIcon={!allowedToCollapse()}
        allowedToCollapse={allowedToCollapse()}
        inProgress={draftGenerationInProgress}
        planCTAFooter={planCtaFooter()}
      />
      <AccordionDetails sx={{ p: 0 }}>
        <TextField
          inputRef={postIdeaInputRef}
          label="Post Idea"
          placeholder="Your new post idea"
          value={postIdeaInputText}
          onKeyDown={handleKeyDown}
          onChange={( event: React.ChangeEvent<HTMLInputElement> ) =>
          {
            const newText = event.target.value;
            setSaveButtonEnabled( hasDifferentInputTextThanSaved( newText ) );
            setPostIdeaInputText( newText );
          }}
          sx={{ mt: 5, mb: 8, px: 4, backgroundColor: "white", borderRadius: "5px", padding: "unset", width: "95%" }}
        />
        <Box sx={{ mb: 10 }} alignItems={"center"}>
          {shouldSupportOutputFormats && <FormControl margin={"normal"} fullWidth>
            <InputLabel id="selectOutputFormat">Output format</InputLabel>
            <Select
              labelId="selectOutputFormat"
              value={selectedOutputFormatId}
              defaultValue={""}
              label="Output format"
              onChange={handleOutputFormatChange}>
              {
                map( outputFormats, ( outputFormat ) =>
                {
                  return <MenuItem key={outputFormat.id} value={outputFormat.id}>{outputFormat.display_name}</MenuItem>;
                } )
              }
            </Select>
          </FormControl>}

          {!loadingIdea && <Link sx={{ mx: 2, color: "text.primary", cursor: "pointer" }} alignItems={"center"} variant="caption"
                                 onClick={handleGenerateIdea}>✨ Generate new idea</Link>}
          {loadingIdea && <Typography variant="caption" sx={{ my: 4, mx: 2, pb: "1px", color: "text.primary" }}>Generating...</Typography>}
        </Box>
        {planCtaFooter()}
      </AccordionDetails>
    </Accordion>
  </Box>
}
