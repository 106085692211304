import { Box, Drawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  MUI_SHARE_DRAWER_CLOSE_REASON_BACKDROP_CLICK,
  ROUTE_PARAM,
  ROUTES,
  SHARE_DRAWER_MODE_CHOOSE_NOW_OR_LATER,
  SHARE_DRAWER_MODE_DIRECT_SHARE,
  SHARE_DRAWER_MODE_SCHEDULE,
  ShareDrawerMode,
  Z_INDEX_DRAWER
} from "../constants";
import { DirectShare } from "../editing/directShare";
import { PostIdeaContext } from "../context/postIdeaContext";
import { PostIdeaDataAPI } from "../assistantChat/assistantChatSlice";
import useNavigateWithSearchParams from "../hooks/useNavigateWithSearchParams";
import { setPlanLastDateClicked } from "../ui/uiSlice";
import { DateUtils } from "../utils/dateUtils";
import { useDispatch } from "react-redux";
import { ShareBatchAPI } from "../../services/postIdeaServices";
import moment from "moment";
import { getEffectiveCompletedDisplayDate, isShareBatchScheduled } from "../postIdea/shareBatchHelper";
import { authenticationServices } from "../../services/authentication.services";
import { SocialNetworkAccountsContext } from "../context/socialNetworkAccountContext";
import { businessServices, SocialNetworkAccountAPI } from "../../services/business.services";
import { ShareChoice } from "../editing/shareChoice";
import { ScheduledShare } from "../editing/scheduledShare";
import { eventTracker } from "../../helpers/eventTracker";

export interface ShareDrawerProps
{
  postIdea: PostIdeaDataAPI;
  previousShareBatch?: ShareBatchAPI;
  showShareDrawer: boolean;
  initialScheduleForDate?: Date;
  closeDrawer: () => void;
  sharedFrom: string;
  onCloseFromBackdropClick?: () => void;
}

export function ShareDrawer( props: ShareDrawerProps )
{
  const navigateWithSearchParams = useNavigateWithSearchParams();
  const dispatch = useDispatch();
  const isRescheduling = !!props.previousShareBatch && isShareBatchScheduled( props.previousShareBatch )
  const initialShareDrawerMode = isRescheduling ? SHARE_DRAWER_MODE_SCHEDULE : SHARE_DRAWER_MODE_CHOOSE_NOW_OR_LATER;
  const [shareDrawerMode, setShareDrawerMode] = useState<ShareDrawerMode>( initialShareDrawerMode );
  const [socialNetworkAccounts, setSocialNetworkAccounts] = React.useState<SocialNetworkAccountAPI[]>( [] );

  useEffect( () =>
  {
    if ( props.showShareDrawer )
    {
      reloadSocialNetworkAccounts();
    }
  }, [props.showShareDrawer] );

  function reloadSocialNetworkAccounts()
  {
    businessServices.listSocialNetworkAccounts().then( ( socialNetworkAccountsResponseAPI ) =>
      {
        setSocialNetworkAccounts( socialNetworkAccountsResponseAPI.social_network_accounts );
      }
    );
  }

  function handleShareComplete( newShareBatch?: ShareBatchAPI )
  {
    closeDrawerAndRefreshSocialNetworkAccounts();

    if ( !!newShareBatch )
    {
      const shareBatchDateToDisplay = getEffectiveCompletedDisplayDate( newShareBatch );
      const shareDateISO = DateUtils.toISODateString( shareBatchDateToDisplay );
      dispatch( setPlanLastDateClicked( shareDateISO ) );

      const wasPreviouslyCompletedShareBatch = !!props.previousShareBatch && !!props.previousShareBatch.completed_at;
      if ( isRescheduling )
      {
        navigateWithSearchParams( ROUTES.PLAN, { [ROUTE_PARAM.PLAN_TAB_REFRESH_REQUEST]: true, [ROUTE_PARAM.PLAN_TAB_DATE]: shareDateISO } );
      }
      else if ( wasPreviouslyCompletedShareBatch && newShareBatch.download_only && !!newShareBatch.completed_at )
      {
        const completedAtDate = new Date( newShareBatch.completed_at );
        const endOfToday = moment().endOf( 'day' )

        if ( moment( completedAtDate ) < endOfToday )
        {
          navigateWithSearchParams( ROUTES.PLAN, { [ROUTE_PARAM.PLAN_TAB_POST_COMPLETE]: true, [ROUTE_PARAM.PLAN_TAB_DATE]: shareDateISO } );
        }
      }
      else
      {
        navigateWithSearchParams( ROUTES.PLAN, { [ROUTE_PARAM.PLAN_TAB_POST_COMPLETE]: true, [ROUTE_PARAM.PLAN_TAB_DATE]: shareDateISO } );
      }

      authenticationServices.refreshUser();
    }
  }

  function handleCancelClicked()
  {
    closeDrawerAndRefreshSocialNetworkAccounts();
  }

  function closeDrawerAndRefreshSocialNetworkAccounts()
  {
    reloadSocialNetworkAccounts();
    setShareDrawerModeOnClose();
    props.closeDrawer();
  }

  function setShareDrawerModeOnClose()
  {
    if ( !isRescheduling )
    {
      setShareDrawerMode( SHARE_DRAWER_MODE_CHOOSE_NOW_OR_LATER );
    }
  }

  function handleShareNowTapped()
  {
    eventTracker.logShareChoiceShareNowClicked( props.postIdea, props.sharedFrom );
    setShareDrawerMode( SHARE_DRAWER_MODE_DIRECT_SHARE );
  }

  function handleScheduleTapped()
  {
    eventTracker.logShareChoiceScheduleClicked( props.postIdea, props.sharedFrom );
    setShareDrawerMode( SHARE_DRAWER_MODE_SCHEDULE );
  }

  function updateSocialNetworkAccounts( socialNetworkAccounts: SocialNetworkAccountAPI[] )
  {
    setSocialNetworkAccounts( socialNetworkAccounts );
  }

  function onClose( event: object, reason: string )
  {
    if ( reason === MUI_SHARE_DRAWER_CLOSE_REASON_BACKDROP_CLICK && props.onCloseFromBackdropClick )
    {
      setShareDrawerModeOnClose();
      props.onCloseFromBackdropClick();
    }
  }

  return <>
    {!!props.postIdea && <PostIdeaContext.Provider value={{ postIdea: props.postIdea }}>
      <SocialNetworkAccountsContext.Provider value={{ socialNetworkAccounts, updateSocialNetworkAccounts }}>

        {props.showShareDrawer && <Drawer
          anchor="bottom"
          sx={{ zIndex: Z_INDEX_DRAWER }}
          onClose={onClose}
          open={props.showShareDrawer}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            {shareDrawerMode === SHARE_DRAWER_MODE_CHOOSE_NOW_OR_LATER && <ShareChoice handleShareNowTapped={handleShareNowTapped}
                                                                                       handleScheduleTapped={handleScheduleTapped}/>}
            {shareDrawerMode === SHARE_DRAWER_MODE_DIRECT_SHARE && <DirectShare sharedFrom={props.sharedFrom}
                                                                                previousShareBatch={props.previousShareBatch}
                                                                                handleShareComplete={handleShareComplete}
                                                                                handleBackClicked={handleCancelClicked}/>}
            {shareDrawerMode === SHARE_DRAWER_MODE_SCHEDULE && <ScheduledShare sharedFrom={props.sharedFrom}
                                                                               previousShareBatch={props.previousShareBatch}
                                                                               initialDate={props.initialScheduleForDate}
                                                                               handleShareComplete={handleShareComplete}
                                                                               handleBackClicked={handleCancelClicked}/>}
          </Box>
        </Drawer>}
      </SocialNetworkAccountsContext.Provider>
    </PostIdeaContext.Provider>}
  </>
}

