import { Accordion, AccordionDetails, Box, Button } from "@mui/material";
import { format } from "date-fns";
import { SHARING_STATUS_FAILED } from "../../services/business.services";
import React, { useContext } from "react";
import AutoModeIcon from '@mui/icons-material/AutoMode';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { PlanItemIconLabel } from "./PlanItemIconLabel";
import { PlanAccordionSummary } from "./planAccordionSummary";
import { useDispatch, useSelector } from "react-redux";
import { PlanItemCtaFooterContainer } from "./planItemCTAFooterContainer";
import { getPlanLastPostIdeaEdited, setUpsellShown } from "../ui/uiSlice";
import { postIdeaServices, ShareBatchAPI } from "../../services/postIdeaServices";
import { filter, map } from "lodash";
import { DateUtils } from "../utils/dateUtils";
import { getEffectiveCompletedDisplayDate, getInitialScheduledTimeForCompletedPost, isShareBatchSending } from "../postIdea/shareBatchHelper";
import { ShareDrawer } from "./shareDrawer";
import { needsRecording } from "../postIdea/postIdeaHelper";
import { RootState } from "../../app/store";
import { shouldBlockForUpsell } from "../user/userSlice";
import SendIcon from "@mui/icons-material/Send";
import { ShareNowTabContentSources } from "../editing/directShare";
import { POST_VIEW_DATE_FORMAT_DAY_DIFFERENT_THAN_PLAN, POST_VIEW_DATE_FORMAT_DAY_SAME_AS_PLAN } from "../constants";
import { SharedVideoAndCaption } from "./sharedVideoAndCaption";
import { ShareBatchAccordionHeader } from "./shareBatchAccordionHeader";
import { PlanDateContext } from "../context/planDateContext";

interface CompletedPostViewProps
{
  shareBatch: ShareBatchAPI;
  handleUpdatePlannerData: () => void;
}

export function CompletedPostView( props: CompletedPostViewProps )
{
  const dispatch = useDispatch();
  const lastPostIdeaEdited = useSelector( ( state ) => getPlanLastPostIdeaEdited( state ) );
  const [expanded, setExpanded] = React.useState<boolean>( lastPostIdeaEdited === props.shareBatch.post_idea.id );
  const failedSocialNetworkPosts = filter( props.shareBatch.social_network_posts || [],
    ( socialNetworkPost ) => socialNetworkPost.send_status === SHARING_STATUS_FAILED );

  const [showShareDrawer, setShowShareDrawer] = React.useState( false );
  const blockForUpsell = useSelector( ( state: RootState ) => shouldBlockForUpsell( state ) );
  const [shareButtonEnabled, setShareButtonEnabled] = React.useState<boolean>( true );

  const planDateContext = useContext( PlanDateContext );
  const planDate = planDateContext.planDate;

  const accentColor = getAccentColor();
  const backgroundColor = getBackgroundColor();

  const handleAccordionClicked = () =>
  {
    if ( !expanded )
    {
      setExpanded( true );
    }
    else
    {
      setExpanded( false );
    }
  }

  function getIcon()
  {
    if ( isShareBatchSending( props.shareBatch ) )
    {
      return <PlanItemIconLabel label="Publishing" color={accentColor}>
        <AutoModeIcon sx={{ color: "primary.contrastText", height: "15px", width: "15px" }}/>
      </PlanItemIconLabel>;
    }
    else if ( failedSocialNetworkPosts.length > 0 && failedSocialNetworkPosts.length === props.shareBatch.social_network_posts?.length )
    {
      return <PlanItemIconLabel label="Attention required" color={accentColor}>
        <ErrorOutlineIcon sx={{ color: "primary.contrastText", height: "15px", width: "15px" }}/>
      </PlanItemIconLabel>;
    }
    else
    {
      return <PlanItemIconLabel label="Completed" color={accentColor}>
        <CheckBoxOutlinedIcon sx={{ color: "primary.contrastText", height: "15px", width: "15px" }}/>
      </PlanItemIconLabel>;
    }
  }

  function getAccentColor()
  {
    return isShareBatchSending( props.shareBatch ) ? "planPublishing.main" : "planCompleted.main";

  }

  function getBackgroundColor()
  {
    return isShareBatchSending( props.shareBatch ) ? "planPublishing.light" : "planCompleted.light";
  }

  function getCompletedPostDateText()
  {
    const dateToUse = getEffectiveCompletedDisplayDate( props.shareBatch );
    let dateFormat = POST_VIEW_DATE_FORMAT_DAY_SAME_AS_PLAN;

    if ( isShareBatchSending( props.shareBatch ) )
    {
      return "";
    }
    else if ( !DateUtils.isSameCalendarDay( planDate, dateToUse ) )
    {
      dateFormat = POST_VIEW_DATE_FORMAT_DAY_DIFFERENT_THAN_PLAN;
    }
    return "Post completed " + format( dateToUse, dateFormat );
  }

  function planCTAFooter()
  {
    return <PlanItemCtaFooterContainer>
      <Button
        startIcon={<SendIcon/>}
        variant="contained"
        disabled={!shareButtonEnabled}
        onClick={handleShareClicked}>
        Share
      </Button>
    </PlanItemCtaFooterContainer>;
  }

  function handleShareClicked()
  {
    if ( !!props.shareBatch.post_idea )
    {
      if ( blockForUpsell && needsRecording( props.shareBatch.post_idea ) )
      {
        dispatch( setUpsellShown( true ) );
      }
      else
      {
        setShareButtonEnabled( false );
        setShowShareDrawer( true );
      }
    }
  }

  function onDrawerClosed()
  {
    setShowShareDrawer( false );
    setShareButtonEnabled( true );
  }

  function handleRetrySocialShare()
  {
    const postIdeaId = props.shareBatch.post_idea.id;
    const socialNetworkPostIdsToRetry = map( failedSocialNetworkPosts, ( socialNetworkPost ) => socialNetworkPost.id );
    const shareBatchId = props.shareBatch.id;
    return postIdeaServices.retryShareToSocialNetworks( postIdeaId, socialNetworkPostIdsToRetry, shareBatchId ).finally( () =>
    {
      props.handleUpdatePlannerData();
    } );
  }

  function getAdditionalHeaderContent()
  {
    if ( !!props.shareBatch )
    {
      return (<ShareBatchAccordionHeader shareBatch={props.shareBatch}
                                         backgroundColor={getAccentColor()}
                                         handleRetrySocialShare={handleRetrySocialShare}></ShareBatchAccordionHeader>);
    }
    return null;
  }

  return <Box sx={{ mb: 5 }}> <Accordion
    expanded={expanded}
    sx={{
      backgroundColor: backgroundColor,
      borderRadius: "5px",
      p: 0,
    }}>
    <PlanAccordionSummary
      onClick={handleAccordionClicked}
      icon={getIcon()}
      savedPostIdeaTitle={props.shareBatch.post_idea?.topic_name}
      accentColor={getAccentColor()}
      helperText={getCompletedPostDateText()}
      expanded={expanded}
      allowedToCollapse={true}
      planCTAFooter={planCTAFooter()}
      additionalHeaderContent={getAdditionalHeaderContent()}
    />
    <AccordionDetails sx={{ p: 0 }}>
      {expanded && <SharedVideoAndCaption videoUrl={props.shareBatch.video_url} caption={props.shareBatch.caption} imageUrl={props.shareBatch.image_url}/>}
      {planCTAFooter()}
    </AccordionDetails>
  </Accordion>
    {showShareDrawer && <ShareDrawer sharedFrom={ShareNowTabContentSources.PLAN}
                                     postIdea={props.shareBatch.post_idea}
                                     previousShareBatch={props.shareBatch}
                                     showShareDrawer={showShareDrawer}
                                     closeDrawer={onDrawerClosed}
                                     onCloseFromBackdropClick={onDrawerClosed}
                                     initialScheduleForDate={getInitialScheduledTimeForCompletedPost( props.shareBatch )}/>}
  </Box>
}
