import { apptimizeWrapper } from "./apptimizeWrapper";

let injectableApptimizeWrapper: typeof apptimizeWrapper;
export const PRICING_TEST_MAY_2024 = "pricingTestMay2024";
export const THREE_MONTHS_50_OFF_PARAM = "threeMonths50Off";
export const EXCLUDE_RECENTLY_USED_MEDIA= "excludeRecentlyUsedMedia";
export const FIRST_POST_INFORMATIVE= "firstPostInformative";
export const INCLUDE_HOLIDAYS_IN_PLAN= "includeHolidaysInPlan";
export const SUPPORTS_OUTPUT_FORMATS= "supportsOutputFormats";

const APPTIMIZE_BOOLEAN_FEATURE_FLAGS: string[] = [EXCLUDE_RECENTLY_USED_MEDIA, FIRST_POST_INFORMATIVE];
export const FEATURE_FLAG_SUFFIX = "FeatureFlag";

function getBool( name: string, defaultValue: boolean ): boolean
{
  return getApptimizeWrapper().getBool( name, defaultValue );
}

function isInExcludeRecentlyUsedMediaTest()
{
  return isFeatureFlagOrVariableSet( EXCLUDE_RECENTLY_USED_MEDIA );
}

function isInFirstPostInformativeTest()
{
  return isFeatureFlagOrVariableSet( FIRST_POST_INFORMATIVE );
}

function shouldIncludeHolidaysInPlan()
{
  return isFeatureFlagOrVariableSet( INCLUDE_HOLIDAYS_IN_PLAN );
}

function supportsOutputFormats()
{
  return isFeatureFlagOrVariableSet( SUPPORTS_OUTPUT_FORMATS );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getString( name: string, defaultValue: string ): string
{
  return getApptimizeWrapper().getString( name, defaultValue );
}

function isFeatureEnabled( name: string ): boolean
{
  const theVariableFeatureFlag = name + FEATURE_FLAG_SUFFIX;
  return getApptimizeWrapper().isFeatureFlagEnabled( theVariableFeatureFlag );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function isFeatureFlagOrVariableSet( key )
{
  return isFeatureEnabled( key ) || getBool( key, false );
}

function getApptimizeWrapper()
{
  return injectableApptimizeWrapper ? injectableApptimizeWrapper : apptimizeWrapper;
}

export const apptimizeVariables = {
  APPTIMIZE_BOOLEAN_FEATURE_FLAGS,
  isInExcludeRecentlyUsedMediaTest,
  isInFirstPostInformativeTest,
  shouldIncludeHolidaysInPlan,
  supportsOutputFormats
};
