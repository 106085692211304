import { Box, Button, Link, Stack, Typography } from "@mui/material";
import { SignOutButton } from "../registration/signOutButton";
import React, { useEffect, useState } from "react";
import { NewBusinessButton } from "../buttons/newBusinessButton";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { isDevModeEnabled } from "../featureFlags/featureFlagsSlice";
import { UserSettingsBusinessDetails } from "./userSettingsBusinessDetails";
import AlertBanner from "../alert/alertBanner";
import {
  hasEmail,
  hasPhoneNumber,
  isGuestUser,
  isSubscribedUser,
  isUserLoggedIn,
  isUserVerificationRequired,
  wasSubscribedUser
} from "../user/userSlice";
import {
  CREATE_ACCOUNT_SHOWN_VIA_USER_SETTINGS,
  CUSTOMER_SUPPORT_MAILTO_URL,
  PRIVACY_POLICY_URL, ROUTE_PARAM,
  ROUTE_SEO,
  ROUTES,
  SURVEY_MONKEY_JUNE_2024_SURVEY_ID,
  TERMS_OF_USE_URL
} from "../constants";
import { CreateAccountOrSignIn } from "../registration/createAccountOrSignIn";
import { authenticationServices } from "../../services/authentication.services";
import { ApptimizeVariablesLogView } from "../apptimize/appitimizeVariablesLogView";
import { stripeServices } from "../../services/stripe.services";
import { setUpsellShown } from "../ui/uiSlice";
import { eventTracker } from "../../helpers/eventTracker";
import { UPSELL_SOURCE_USER_SETTINGS } from "../../helpers/trackingConstants";
import { ALKAI_VERSION } from "../../constants";
import useNavigateWithSearchParams from "../hooks/useNavigateWithSearchParams";
import { getBusinessId, getFirstPostDrafted, hasBrandStyle, isSubscribedAllNotifications } from "../business/businessSlice";
import { Helmet } from "react-helmet";
import { SurveyLink } from "./surveyLink";
import { SocialLinksSection } from "./SocialLinksSection";
import { PostingStrategyQuizFullScreenDialog } from "../postingStrategyQuiz/postingStrategyQuizFullScreenDialog";
import { businessServices } from "../../services/business.services";
import { NotificationSubscriptionToggle } from "./NotificationSubscriptionToggle";
import { SocialNetworkAccountsSection } from "./SocialNetworkAccountsSection";
import { UrlUtils } from "../utils/urlUtils";
import { useSearchParams } from "react-router-dom";

export function UserSettings()
{
  const dispatch = useDispatch();
  const navigateWithSearchParams = useNavigateWithSearchParams();

  const devModeEnabled = useSelector( ( state: RootState ) => isDevModeEnabled( state ) );
  const userIsLoggedIn = useSelector( ( state: RootState ) => isUserLoggedIn( state ) );
  const isGuest = useSelector( ( state: RootState ) => isGuestUser( state ) );
  const isVerificationRequired = useSelector( ( state: RootState ) => isUserVerificationRequired( state ) );
  const [showCreateLogin, setShowCreateLogin] = useState<boolean>( isVerificationRequired );
  const [showPostingStrategyQuiz, setShowPostingStrategyQuiz] = useState<boolean>( false );
  const shouldShowCheckoutButton = useSelector( ( state: RootState ) => !isSubscribedUser( state ) && getFirstPostDrafted( state ) );
  const wasSubscribed = useSelector( ( state: RootState ) => wasSubscribedUser( state ) );
  const isSubscribed = useSelector( ( state: RootState ) => isSubscribedUser( state ) );
  const shouldShowCustomerPortal = isSubscribed || wasSubscribed;
  const shouldShowBrandStyle = useSelector( ( state: RootState ) => hasBrandStyle( state ) );
  const businessId = useSelector( ( state: RootState ) => getBusinessId( state ) ) || "";
  const isSubscribedFromAllNotifications = useSelector( ( state: RootState ) => isSubscribedAllNotifications( state ) );
  const [isSubscribedFromAllNotificationsToggleOn, setIsSubscribedFromAllNotificationsToggleOn] = useState<boolean>(
    isSubscribedFromAllNotifications );

  const hasPhoneChannel = useSelector( ( state: RootState ) => hasPhoneNumber( state ) );
  const hasEmailChannel = useSelector( ( state: RootState ) => hasEmail( state ) );
  const hasCommunicationChannel = hasPhoneChannel || hasEmailChannel;
  const [searchParams, setSearchParams] = useSearchParams();
  const navigatedWithUpsellParam = UrlUtils.isParamValueTrue( searchParams.get( ROUTE_PARAM.SETTINGS_TAB_UPSELL ) )

  useEffect( () =>
  {
    authenticationServices.refreshUser().then( ( data ) =>
    {
      handleUpsellDeeplink();
    } );
  }, [] );

  useEffect( () =>
  {
    setIsSubscribedFromAllNotificationsToggleOn( isSubscribedFromAllNotifications );
  }, [isSubscribedFromAllNotifications] );

  function handleUpsellDeeplink()
  {
    if ( navigatedWithUpsellParam )
    {
      searchParams.delete( ROUTE_PARAM.SETTINGS_TAB_UPSELL );
      setSearchParams( searchParams );

      if ( !isSubscribed )
      {
        dispatch( setUpsellShown( true ) );
      }
    }
  }

  function handleCreateLoginClicked()
  {
    setShowCreateLogin( !showCreateLogin );
  }

  function handleDialogOnClose()
  {
    setShowCreateLogin( false );
  }

  function handleCheckoutClicked()
  {
    dispatch( setUpsellShown( true ) )
    eventTracker.logUpsellShown( UPSELL_SOURCE_USER_SETTINGS );
  }

  function handleClickOnCustomerPortal()
  {
    eventTracker.logManageSubscriptionClicked();
    stripeServices.customerPortal();
  }

  function handleNavigateToBranding()
  {
    eventTracker.logStyleAndBrandingClicked( businessId );
    navigateWithSearchParams( ROUTES.BRANDING );
  }

  function handleShowPostingStrategyQuiz()
  {
    setShowPostingStrategyQuiz( true );
  }

  function closePostingStrategyQuiz()
  {
    setShowPostingStrategyQuiz( false );
  }

  function onSubscribeAllNotificationsChange( event: React.ChangeEvent<HTMLInputElement> )
  {
    const newValue = event.target.checked;
    setIsSubscribedFromAllNotificationsToggleOn( newValue );

    eventTracker.logUnsubscribedAllNotificationsChanged( !newValue );
    businessServices.updateUnsubscribedFromNotifications( !newValue );
  }

  return <Stack spacing={10} sx={{ textAlign: "left", px: 5 }}>
    <Helmet>
      <title>{ROUTE_SEO.USER_SETTINGS.title}</title>
      <meta name="description" content={ROUTE_SEO.USER_SETTINGS.description}/>
    </Helmet>
    <Typography variant={"h4"}>Settings</Typography>
    {userIsLoggedIn && <UserSettingsBusinessDetails/>}
    <SocialNetworkAccountsSection/>
    {shouldShowBrandStyle && <Link onClick={handleNavigateToBranding} sx={{ cursor: "pointer" }}>
      <Typography variant={"h5"}>Brand kit</Typography>
    </Link>}
    <SurveyLink variant="subtitle1" surveyId={SURVEY_MONKEY_JUNE_2024_SURVEY_ID}/>
    <SocialLinksSection/>

    <Typography variant="body1">
      <Link href={CUSTOMER_SUPPORT_MAILTO_URL}>Contact support</Link>
    </Typography>

    <Box>
      {shouldShowCustomerPortal && <Link variant="h5" sx={{ cursor: "pointer" }} onClick={handleClickOnCustomerPortal}>Manage subscription</Link>}
      {wasSubscribed && !isSubscribed && <Typography sx={{ textAlign: "left" }} variant="body2">Your subscription is no longer active.<br/> Please
                                                                                                renew to continue using Alkai.</Typography>}
    </Box>

    {
      hasCommunicationChannel &&
      <NotificationSubscriptionToggle isToggleOn={isSubscribedFromAllNotificationsToggleOn} onChange={onSubscribeAllNotificationsChange}/>
    }

    {shouldShowCheckoutButton && <Link variant="h5" onClick={handleCheckoutClicked} sx={{ cursor: "pointer" }}>
      Subscribe now
    </Link>}

    {devModeEnabled && <Link variant="h5" onClick={handleShowPostingStrategyQuiz}>
      Show posting strategy quiz
    </Link>}

    <Typography sx={{ pt: 15 }} variant="body1">
      <Link href={TERMS_OF_USE_URL} rel="noopener" target="_blank">Terms of Use</Link>
      <br/>
      <Link href={PRIVACY_POLICY_URL} rel="noopener" target="_blank">Privacy Policy</Link>
    </Typography>
    {isGuest && <Button variant="outlined" onClick={handleCreateLoginClicked}>Create account</Button>}
    {userIsLoggedIn && <SignOutButton/>}
    {devModeEnabled && <NewBusinessButton/>}
    {devModeEnabled && <ApptimizeVariablesLogView/>}
    {devModeEnabled && <Typography variant="subtitle2">App Version: {ALKAI_VERSION}</Typography>}
    {showCreateLogin && <CreateAccountOrSignIn shownVia={CREATE_ACCOUNT_SHOWN_VIA_USER_SETTINGS}
                                               shouldShowSignIn={showCreateLogin}
                                               onClose={handleDialogOnClose}/>}
    {devModeEnabled && showPostingStrategyQuiz && <PostingStrategyQuizFullScreenDialog manageOpenStateExternally={true}
                                                                                       externalStateOpen={showPostingStrategyQuiz}
                                                                                       hideTopBar={true}
                                                                                       handleClose={closePostingStrategyQuiz}
                                                                                       modalDialogSxProps={{ maxWidth: "400px", mx: "auto" }}
    />}
    <AlertBanner/>
  </Stack>
}
