import { PostPreview } from "../postIdea/postPreview";
import React from "react";
import { Post } from "../postIdea/postsSlice";
import { SxProps } from "@mui/material";
import { merge } from "lodash";

export interface BrandCardPreviewProps
{
  showInstagramOverlay: boolean;
  post: Post;
  showReplay: boolean;
  sx?: SxProps;
}

export function BrandCardPreview( props: BrandCardPreviewProps )
{

  return (<PostPreview post={props.post}
                       iframeId="brandCardPreview"
                       showSafeArea={false}
                       caption=""
                       showInstagramOverlay={props.showInstagramOverlay}
                       hidePlayButtonOnPause={true}
                       showReplayButtonBelow={props.showReplay}
                       showBorder={true}
                       customSxProps={merge( { mt: 22 }, props.sx )}/>);
}
